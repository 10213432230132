/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDzxh-yMbekNmcVTx2xMWg0mrJeGYwu9Ps",
  "appId": "1:882105477552:web:14c18fd432579c93174e75",
  "authDomain": "schooldog-i-charlton-ga.firebaseapp.com",
  "measurementId": "G-ZPQQZMFPJ6",
  "messagingSenderId": "882105477552",
  "project": "schooldog-i-charlton-ga",
  "projectId": "schooldog-i-charlton-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-charlton-ga.appspot.com"
}
